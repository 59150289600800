<template>
  <div class="order">
    <div class="head head-box flex-c">
      <div class="cn-info">订单支持</div>
      <div class="en-info">ORDER SUPPORT</div>
    </div>

    <div class="into-menu flex-r" style="width: 100%">
      <div
        class="into-menu-btn"
        @click="goPoint(0)"
        :class="{ 'info-menu-btn-active': point == 0 }"
      >
        下单流程
      </div>
      <div
        class="into-menu-btn"
        @click="goPoint(1)"
        :class="{ 'info-menu-btn-active': point == 1 }"
      >
        订单跟踪
      </div>
    </div>
    <div class="into-menu-line"></div>
    <div v-if="point==0" style="margin: 40px">
      <div>
        <div class="head-info">
          <div class="head-text">小程序下单</div>
          <div class="head-sub-text">ORDER APPOINTMENT</div>
          <el-image
            style="width: 140px"
            :src="require('@/assets/order/qrcode.png')"
          ></el-image>
          <div class="head-sub-text2">微信扫描二维码</div>
          <div class="head-sub-text2">关注公众号</div>
        </div>
      </div>
      <el-image
        style="width: 100%"
        :src="require('@/assets/order/lc.png')"
      ></el-image>
    </div>
    <div v-else>
        <iframe class="frame"  :src="orderUrl"></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "Order",
  setup() {},
  data(){
    return{
        point:0,
        orderUrl:'https://wx.360scm.com/WeChat.TMS.ExecApp/spquery/index.html?apikey=B9BB97D47CCA46FD96949D7D85298A15&host=tms.bioquick.com#/'
    }
  },
  methods:{
    goPoint(idx){
        this.point= idx;
    }
  }
};
</script>
<style scoped>
.order .head {
  position: relative;
  color: white;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
  background-image: url("~@/assets/order/bg.png");
  background-size: cover;
  background-position: center;
  padding: 40px 0;
}
.order .head-text {
  font-weight: 900;
  font-size: 18pt;
}
.order .head-sub-text {
  font-size: 8pt;
}

.head-box {
  width: 100%;
  height: calc(100vw * 0.2);
  max-height: 700px;
  min-height: 300px;
  color: white;
  font-weight: 900;
}

.head-box .cn-info {
  font-size: 48px;
  text-shadow: 2px 2px 4px black;
}
.head-box .en-info {
  font-size: 22px;
  text-shadow: 1px 1px 4px black;
}
.frame{
    margin:40px;
    padding:10px;
    width: calc(100% - 80px);
    height:calc(100vw * 0.6);
    min-height:700px;
    border:none;
    border:2px solid #c8c8c8;
}
</style>
