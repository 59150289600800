<template>
  <div class="about">
    <div class="head">
    </div>
    <div class="flex-c con-box">
      <div class="head-text">联系我们</div>
      <div class="info-area flex-c" style="gap: 80px">
        <div class="info-box flex-c" style="align-items: flex-start; gap: 20px">
          <el-image style="width:200px" :src="require('@/assets/main/logo.svg')"></el-image>
          <div class="line"></div>
          <div class="info-box-title" style="font-weight: 900">
            上海佰诚医药供应链管理有限公司总部
          </div>
          <div class="flex-r" style="gap: 25px">
            <el-image
              style="width: 22px"
              :src="require('@/assets/about/phone.png')"
            ></el-image>
            <div class="info-box-text" style="font-weight: 900">
              021-33282800
            </div>
          </div>
          <div class="flex-r" style="gap: 25px">
            <el-image
              style="width: 22px"
              :src="require('@/assets/about/mail.png')"
            ></el-image>
            <div class="info-box-text" style="font-weight: 900">
              pharm@bioquick.com
            </div>
          </div>
          <div class="flex-r" style="gap: 25px">
            <el-image
              style="width: 22px"
              :src="require('@/assets/about/web.png')"
            ></el-image>
            <div class="info-box-text" style="font-weight: 900">
              www.bioquick.com
            </div>
          </div>
          <div class="flex-r" style="gap: 25px">
            <el-image
              style="width: 22px"
              :src="require('@/assets/about/call.png')"
            ></el-image>
            <div class="info-box-text" style="font-weight: 900">
              400-858-2121
            </div>
          </div>
          <div class="flex-r" style="gap: 25px">
            <el-image
              style="width: 22px"
              :src="require('@/assets/about/address.png')"
            ></el-image>
            <div class="info-box-text" style="font-weight: 900">
              上海市闵行区申滨南路1126号龙湖天街C栋306室
            </div>
          </div>
          <div class="flex-r" style="gap: 25px">
            <el-image
              style="width: 22px"
              :src="require('@/assets/about/code.png')"
            ></el-image>
            <div class="info-box-text" style="font-weight: 900">201105</div>
          </div>
        </div>
        <div class="info-box flex-c" style="align-items: flex-start">
          <div
            v-for="item in addressList"
            class="flex-r add-box"
            style="justify-content: space-between"
          >
            <div
              class="flex-c"
              style="align-items: flex-start; gap: 8px; margin-top: 20px"
            >
              <div class="add-title">{{ item.title }}</div>
              <div class="flex-r" style="gap: 25px">
                <el-image
                  style="width: 18px"
                  :src="require('@/assets/about/pt.png')"
                ></el-image>
                <div class="add-text">{{ item.address }}</div>
              </div>
            </div>
            <!-- <div class="add-btn">点击获取地址</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {},
  data() {
    return {
      addressList: [
        {
          title: "中国上海临床药品管理中心",
          address: "上海市松江区连富路858号5、7、9号仓库",
        },
        {
          title: "中国北京临床药品管理中心",
          address: "北京市通州区联东U谷东区6D一层西侧",
        },
        {
          title: "中国杭州临床药品管理中心（GSP）址",
          address: "杭州市萧山区许贤工业园区2幢一楼西冷库",
        },
        {
          title: "中国无锡临床药品管理中心",
          address: "无锡市新吴区新安街道清源路20号立业楼C316",
        },
        {
          title: "美国新泽西临床药品管理中心址",
          address: "5 IIene Ct.,Bldg.7,Unit 17,Hillsborough, NJ 08844, USA",
        },
      ],
    };
  },
};
</script>
<style scoped>
.about {
  width: 100%;
  background: #f8f9ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.about .head {
  position: absolute;
  z-index: 1;
  background-image: url("~@/assets/about/bg.png");
    background-size: cover;
    background-position: center;
    width:100%;
    height:400px;
}
.head-text {
  color: var(--m-color);
  font-size: 24pt;
  font-weight: 900;
  margin-left: 50px;
}
.head-text::before {
  content: "";
  width: 8px;
  height: 50px;
  background: var(--m-color);
  position: absolute;
  margin-left: -40px;
}
.about .info-area {
  z-index: 100;
  width:100%;
}
.about .info-box {
  width: 100%;
  padding: 30px;
  padding-left: 60px;
  background: white;

  /* border: 1px solid black; */
}
.info-box .line {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  margin-top: -10px;
}
.info-box-title {
  color: var(--m-color);
  font-size: 20pt;
}

.info-box-text {
  font-size: 16pt;
}
.add-box {
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.6);
  padding-bottom: 20px;
}
.add-box .add-title {
  font-size: 20pt;
  color: var(--m-color);
}
.add-box .add-btn {
  color: var(--m-color);
  width: 100px;
  border-radius: 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: 1px solid var(--m-color);
}
.con-box{
  z-index:100;
  align-items: flex-start;
  margin-top:80px;
  gap:60px;
  width:50%;
  max-width: 1200px;
}

@media screen and (min-width:1430px) and (max-width:1910px) {
  .con-box{
    width:60vw;
  }
}
@media screen and (min-width:1014px) and (max-width:1430px) {
  .con-box{
    width:80vw;
  }
}
@media screen  and (max-width:1014px) {
  .con-box{
    width:80vw;
  }
}
</style>
