<template>
  <div id="nav" class="flex-r">
    <div class="flex-r">
      <div
        class="menulist-menu"
        :class="{ animate: showMenu }"
        @click.stop="showMenu = !showMenu"
      ></div>
      <el-image
        class="logo"
        style="height: 56px"
        :src="require('@/assets/main/logo.svg')"
      />
    </div>
    <div class="top-menu flex-r">
      <router-link class="flex-r" to="/">首&nbsp&nbsp&nbsp页</router-link>
      <router-link class="flex-r" to="/into">走进佰诚</router-link>
      <router-link class="flex-r" to="/service">服务领域</router-link>
      <router-link class="flex-r" to="/order">订单支持</router-link>
      <!-- <router-link to="/joinus">加入我们</router-link> -->
      <router-link class="flex-r" to="/about">联系我们</router-link>
    </div>
    <div class="title-right flex-r">
      <!-- <div class="lan-box">{{ lan=='CN'?"EN":'中'}}</div> -->
      <el-image
        class="phone-img"
        style="height: 36px"
        :src="require('@/assets/main/phone.png')"
      />
      <div class="phone-number">400-858-2121</div>
    </div>
  </div>
  <div
    class="flex-c left-side-menu"
    :class="{ 'left-side-menu-show': showMenu }"
  >
    <router-link class="flex-r" to="/">首&nbsp&nbsp&nbsp页</router-link>
    <router-link class="flex-r" to="/into">走进佰诚</router-link>
    <router-link class="flex-r" to="/service">服务领域</router-link>
    <router-link class="flex-r" to="/order">订单支持</router-link>
    <!-- <router-link to="/joinus">加入我们</router-link> -->
    <router-link class="flex-r" to="/about">联系我们</router-link>
  </div>
  <router-view class="main-view" />
  <div class="foot flex-c">
    <div class="foot-box flex-c">
      <div
        class="foot-info flex-r"
        style="gap: 30px; align-items: flex-end; justify-content: space-between"
      >
        <div class="info-col flex-c flex-start">
          <el-image
            class="logo"
            style="height: 56px"
            :src="require('@/assets/main/logo.svg')"
          />
          <div>
            联&nbsp&nbsp&nbsp&nbsp系&nbsp&nbsp&nbsp&nbsp电&nbsp&nbsp&nbsp&nbsp话：021-3328
            2800
          </div>
          <div>24&nbsp小时服务热线：400-858-2121</div>
          <div>
            电&nbsp&nbsp&nbsp&nbsp子&nbsp&nbsp&nbsp&nbsp邮&nbsp&nbsp&nbsp&nbsp箱：pharm@bioquick.com
          </div>
          <div style="margin-top: 20px">
            1.中&nbsp&nbsp&nbsp&nbsp国&nbsp&nbsp&nbsp&nbsp上&nbsp&nbsp&nbsp&nbsp海&nbsp&nbsp&nbsp&nbsp总&nbsp&nbsp&nbsp&nbsp部：上海市闵行区申滨南路1126号龙湖虹桥天街C栋306室
          </div>
          <div>
            2.中国上海临床药品管理中心：上海松江区连富路858号5、7、9号仓库
          </div>
          <div>
            3.中国北京临床药品管理中心：北京市通州区联东U谷东区6D一层西侧
          </div>
          <div>
            4.中国杭州临床药品管理中心（GSP）址：杭州市萧山区许贤工业园区2幢一楼西冷库
          </div>
          <div>
            5.中国无锡临床药品管理中心：无锡市新吴区新安街道清源路20号立业楼C316
          </div>
          <div>
            6.美国新泽西临床药品管理中心址：5 IIene Ct.,Bldg.7,Unit
            17,Hillsborough, NJ 08844, USA
          </div>
        </div>
        <div class="info-col flex-c">
          <el-image
            style="width: 120px"
            :src="require('@/assets/main/qrcode.jpg')"
          ></el-image>
          <div style="font-size: 14px; margin-top: 6px">
            上海佰诚医药供应链管理
          </div>
          <div style="font-size: 8px">微信扫描二维码 关注我的公众号</div>
        </div>
      </div>
      
    </div>
    <div style="color:white">copyright© 2020 上海佰诚医药供应链管理有限公司. All Rights Reserved. <a class="linkd" href="http://beian.miit.gov.cn/">沪ICP备2020034573号-1</a> </div>
  </div>
  
</template>
<script>
export default {
  setup() {},
  data() {
    return {
      lan: "CN",
      showMenu: false,
    };
  },
  mounted() {
    console.log(process.env);
  },
};
</script>

<style>
:root {
  --headHeight: 80px;
  --m-color: #0083bd;
  --s-color: #6bbc6e;
  --sub-text-color: #303133;
}
html,
body {
  /* min-width: 1920px; */
  margin: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  scroll-behavior: smooth;
  width: 100%;
  min-width: 710px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 1900px;
  color: var(--sub-text-color);
}

#nav {
  height: var(--headHeight);
  font-size: 16pt;
  z-index: 100;
  margin: 0 120px;
  justify-content: space-between;
  transition: all 0.3s ease;
}
.menulist-menu {
  display: none;
}

.menulist-menu,
.menulist-menu:after,
.menulist-menu:before {
  width: 30px;
  height: 5px;
  cursor: pointer;
}
.menulist-menu {
  position: relative;
  background: #333333;
  transition: all 0ms 300ms;
}
.menulist-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  background: var(--m-color);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menulist-menu.animate {
  background: rgba(255, 255, 255, 0);
}
.menulist-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  background: var(--s-color);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menulist-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menulist-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.left-side-menu {
  justify-content: flex-start !important;
  gap: 8px;
  left: -160px;
  width: 160px;
  position: fixed;
  height: calc(100vh - var(--headHeight));
  top: var(--headHeight);
  background: #f0f0f0;
  z-index: 1000;
  font-size: 16pt;
  transition: all 0.3s ease;
}

.left-side-menu-show {
  left: 0;
}

.top-menu {
  /* gap:30px; */
  transition: all 0.3s ease;
}

#nav a,
.left-side-menu a {
  width: 200px;
  text-decoration: none;
  color: black;
  line-height: 16pt;
  height: 40px;
  transition: all ease 0.5s;
}
.left-side-menu a {
  width: 160px;
  height: 60px;
}

#nav a.router-link-exact-active,
.left-side-menu a.router-link-exact-active {
  color: #ffffff;
  background: var(--m-color);
}

.lan-box {
  background: var(--m-color);
  width: 36px;
  height: 36px;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12pt;
}
.phone-img {
  margin-left: 10px;
}
.phone-number {
  margin-left: 10px;
  font-size: 14pt;
}
.foot {
  background: #3f3a39;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.foot-box {
  font-size: 14px;
  padding: 20px 0;
  width: 80%;
  color: white;
  font-weight: 500;
  line-height: 2;
}
.foot-info {
  width: 100%;
  padding-left: 12px;
}
.point-line::before {
  position: absolute;
  content: "●";
  margin-left: -12px;
}
.v-line {
  height: 75%;
  width: 1px;
  background: black;
}
.info-col {
  justify-content: space-between !important;
}
.linkd{
  color:white
}
.linkd:visited{
  color:white
}

@media screen and (min-width: 1430px) and (max-width: 1910px) {
  #app {
    width: 100%;
    max-width: 1900px;
  }
  #nav a,
  .left-side-menu a {
    width: 150px;
  }
  #nav {
    margin: 0 80px;
  }
}

@media screen and (min-width: 1014px) and (max-width: 1430px) {
  #app {
    width: 100%;
    max-width: 1430px;
  }
  #nav a,
  .left-side-menu a {
    width: 120px;
  }
  #nav {
    margin: 0 20px;
  }
  .top-menu {
    /* gap:8px; */
  }
}

@media screen and (max-width: 1014px) {
  #app {
    width: 100%;
    max-width: 1070px;
  }
  #nav {
    margin: 0 20px;
  }
  .menulist-menu {
    display: inline;
    margin-right: 20px;
  }
  .top-menu {
    /* gap:0; */
    display: none !important;
  }
}
</style>
