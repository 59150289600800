<template>
  <div id="into">
    <div class="head-box flex-c">
      <div class="cn-info">一站式医药供应链服务管理平台</div>
      <div class="en-info">
        ONE-STOP CLINICAL SUPPLY CHAIN MANAGEMENT SERVICE PROVIDER
      </div>
    </div>
    <div class="into-menu flex-r" style="width: 100%">
      <div
        class="into-menu-btn"
        @click="goPoint(0)"
        :class="{ 'info-menu-btn-active': point == 0 }"
      >
        公司简介
      </div>
      <div
        class="into-menu-btn"
        @click="goPoint(1)"
        :class="{ 'info-menu-btn-active': point == 1 }"
      >
        佰诚大事件
      </div>
      <div
        class="into-menu-btn"
        @click="goPoint(2)"
        :class="{ 'info-menu-btn-active': point == 2 }"
      >
        企业文化
      </div>
      <div
        class="into-menu-btn"
        @click="goPoint(3)"
        :class="{ 'info-menu-btn-active': point == 3 }"
      >
        资质荣誉
      </div>
    </div>
    <div class="into-menu-line"></div>

    <div id="point0" class="flex-c">
      <div class="comp-info-title flex-c">
        <div class="title">公司简介</div>
        <div class="flex-r">
          <el-image
            style="margin-top: 6px; margin-right: 10px"
            :src="require('@/assets/into/icon1.png')"
          ></el-image>
          <div class="sub-title">上海佰诚医药供应链管理有限公司</div>
        </div>
      </div>
      <div class="flex-r" style="gap: 50px">
        <div
          class="info flex-c"
          style="
            text-align: left;
            align-items: flex-start;
            justify-content: space-between;
          "
        >
          <div>佰诚是面向全球临床试验的一站式供应链管理服务平台。</div>
          <div>
            2020年成立以来，佰诚深耕于生命科学、生物技术、药物研发等领域的服务，为各大医药研发企业、CRO、CMO、Site中心、实验室，提供合规的供应链解决方案、全链条综合服务和全方位数据支持。
          </div>
          <div>
            佰诚专业提供全球多仓化药品管理、对照药/联合用药全球采购，试验药品二次包装与贴标、临床试验发药与药品回收/销毁、冷链运输、进出口与跨境物流、临床设备与医疗器械综合服务、样本存储、系统数据对接等全方位供应链综合服务。
          </div>
        </div>  
        <el-image
          class="info-img"
          :src="require('@/assets/into/info-img.png')"
        ></el-image>
      </div>
      <div class="flex-r" style="margin-top: 60px">
        <div class="flex-c flex-start flex-box-row">
          <div class="sub-row-box flex-r" style="justify-content: flex-start">
            <el-image
              class="icon"
              :src="require('@/assets/main/icon1.png')"
            ></el-image>
            <div class="flex-c" style="align-items: flex-start">
              <div class="flex-r">
                <div class="num">30</div>
                <div class="up">+</div>
              </div>
              <div class="sub-text">全国自营网点</div>
            </div>
          </div>
          <div class="sub-row-box flex-r" style="justify-content: flex-start">
            <el-image
              class="icon"
              :src="require('@/assets/main/icon4.png')"
            ></el-image>
            <div class="flex-c" style="align-items: flex-start">
              <div class="flex-r">
                <div class="num">4000</div>
                <div class="down">m²</div>
              </div>
              <div class="sub-text">数字化多温区仓储管理</div>
            </div>
          </div>
        </div>
        <div class="sub-row-line"></div>
        <div class="flex-c flex-start flex-box-row">
          <div class="sub-row-box flex-r" style="justify-content: flex-start">
            <el-image
              class="icon"
              :src="require('@/assets/main/icon2.png')"
            ></el-image>
            <div class="flex-c" style="align-items: flex-start">
              <div class="flex-r">
                <div class="num">150</div>
                <div class="up">+</div>
              </div>
              <div class="sub-text">覆盖城市</div>
            </div>
          </div>
          <div class="sub-row-box flex-r" style="justify-content: flex-start">
            <el-image
              class="icon"
              :src="require('@/assets/main/icon5.png')"
            ></el-image>
            <div class="flex-c" style="align-items: flex-start">
              <div class="flex-r">
                <div class="num">50</div>
                <div class="up">+</div>
              </div>
              <div class="sub-text">药企战略合作客户</div>
            </div>
          </div>
        </div>
        <div class="sub-row-line"></div>
        <div class="flex-c flex-box-row">
          <div class="sub-row-box flex-r" style="justify-content: flex-start">
            <el-image
              class="icon"
              :src="require('@/assets/main/icon3.png')"
            ></el-image>
            <div class="flex-c" style="align-items: flex-start">
              <div class="flex-r">
                <div class="num">98</div>
                <div class="down">%</div>
              </div>
              <div class="sub-text">覆盖全国行政县</div>
            </div>
          </div>
          <div class="sub-row-box flex-r" style="justify-content: flex-start">
            <el-image
              class="icon"
              :src="require('@/assets/main/icon6.png')"
            ></el-image>
            <div class="flex-c" style="align-items: flex-start">
              <div class="flex-r">
                <div class="num">800</div>
                <div class="up">+</div>
              </div>
              <div class="sub-text">对照药/采购药合作供应商</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="point1"
      class="main-sub2"
      style="background-color: #fafbff; margin-top: 60px"
    >
      <div class="sub-title flex-c">
        <div class="main-title">佰诚大事件</div>
        <div class="line flex-c">
          <div class="point"></div>
        </div>
        <div class="sub-title-text">IMPORTANT DEVELOPMENT PROCESS</div>
      </div>
      <el-image :src="require('@/assets/into/dsj.png')"></el-image>
    </div>
    <div id="point2" class="main-sub2" style="margin-top: 60px">
      <div class="sub-title flex-c">
        <div class="main-title">企业文化</div>
        <div class="line flex-c">
          <div class="point"></div>
        </div>
        <div class="sub-title-text">CORPORATE CULTURE</div>
      </div>
      <div class="flex-r">
        <div class="sm sm1 flex-c" style="justify-content: flex-start">
          <el-image
            style="margin-top: 60px; margin-bottom: 10px"
            :src="require('@/assets/into/smicon1.png')"
          ></el-image>
          <div class="sm-title">愿 景</div>
          <div class="sm-sub-title">成为医药健康产业供应链服务标杆企业</div>
        </div>
        <div class="sm sm2 flex-c" style="justify-content: flex-start">
          <el-image
            style="margin-top: 60px; margin-bottom: 10px"
            :src="require('@/assets/into/smicon2.png')"
          ></el-image>
          <div class="sm-title">使 命</div>
          <div class="sm-sub-title">为医药健康产品提供全生命链的呵护保障</div>
          <div class="sm-sub-title">为行业标准进步不断贡献力量</div>
        </div>
        <div class="sm sm3 flex-c" style="justify-content: flex-start">
          <el-image
            style="margin-top: 60px; margin-bottom: 10px"
            :src="require('@/assets/into/smicon3.png')"
          ></el-image>
          <div class="sm-title">价值观</div>
          <div class="sm-sub-title">创新 诚信 高效 共赢</div>
        </div>
      </div>
    </div>
    <div
      id="point3"
      class="main-sub2"
      style="margin-top: 60px; margin-bottom: 40px"
    >
      <div class="sub-title flex-c">
        <div class="main-title">资质荣誉</div>
        <div class="line flex-c">
          <div class="point"></div>
        </div>
        <div class="sub-title-text">QUALIFICATIONS AND HONORS</div>
      </div>
      <div class="zz flex-c" style="margin-top: 0">
        <div class="sm-title">2023年</div>
        <el-image
          style="margin-bottom: 40px"
          :src="require('@/assets/into/jt.png')"
        ></el-image>
        <div class="sm-sub-title">中国医药质量管理协会临床试验合同研究组织（CRO）分会 委员单位 
</div>
<div class="sm-sub-title">中国物流与采购联合会医疗器械供应链分会会员单位</div>
        <div class="sm-sub-title">
          中国物流与采购联合会医药物流分会常务理事单位
        </div>
        <div class="sm-sub-title">中国物流与采购联合会航空物流分会理事单位</div>
      
        
      </div>
      <div class="zz flex-c">
        <div class="sm-title">2022年</div>
        <el-image
          style="margin-bottom: 40px"
          :src="require('@/assets/into/jt.png')"
        ></el-image>
        <div class="sm-sub-title">
          中国物流与采购联合会医药物流分会常务理事单位
        </div>
        <div class="sm-sub-title">中国物流与采购联合会航空物流分会理事单位</div>
      </div>
      <div class="zz flex-c">
        <div class="sm-title">2021年</div>
        <el-image
          style="margin-bottom: 40px"
          :src="require('@/assets/into/jt.png')"
        ></el-image>
        <div class="sm-sub-title">中华全国工商业联合会会员</div>
        <div class="sm-sub-title">上海市工商业联合会会员</div>
        <div class="sm-sub-title">上海市总商会会员</div>
      </div>
      <div class="zz flex-c">
        <div class="sm-title">2020年</div>
        <el-image
          style="margin-bottom: 40px"
          :src="require('@/assets/into/jt.png')"
        ></el-image>
        <div class="sm-sub-title">
          中国物流与采购联合会医药物流分会常务理事单位
        </div>
        <div class="sm-sub-title">中国物流与采购联合会航空物流分会理事单位</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Into",
  setup() {},
  data() {
    return {
      point: 0,
    };
  },
  methods: {
    goPoint(idx) {
      console.log("point" + idx);
      this.point = idx;
      document.documentElement.scrollTop =
        document.getElementById("point" + idx).offsetTop - 72;
    },
  },
};
</script>
<style scoped>
#into .head-box {
  background-image: url("~@/assets/into/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vw * 0.3);
  max-height: 700px;
  min-height: 300px;
  color: white;
  font-weight: 900;
}
.head-box .cn-info {
  font-size: 48px;
  text-shadow: 2px 2px 4px black;
}
.head-box .en-info {
  font-size: 22px;
  text-shadow: 1px 1px 4px black;
}

.comp-info-title {
  height: 200px;
  width: 1150px;
  background-image: url("~@/assets/into/about-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  border-bottom: 4px solid #c8c8c8;
  margin-bottom: 40px;
}
.comp-info-title .title {
  font-size: 36px;
  font-weight: 900;
}
.comp-info-title .sub-title {
  font-size: 16pt;
  font-weight: 900;
}
#point0 .info {
  font-size: 18px;
  line-height: 36px;
  width: 600px;
  text-indent: 30px;
}
#point0 .info-img {
  width: 500px;
}

.flex-num-box {
  gap: 60px;
}

.flex-box-row {
  gap: 60px;
}
.sub-row-box {
  width: 240px;
  height: 80px;
  /* background: rgba(0,0,0,0.2); */
  margin-left: 5px;
  margin-right: 5px;
}
.sub-row-box .icon {
  /* width:24px; */
  margin-right: 20px;
  margin-top: 10px;
}
.sub-row-box .num {
  font-size: 42pt;
  font-weight: 900;
  font-family: "bah";
}

.sub-row-box .up {
  font-size: 20pt;
  font-weight: 900;
  margin-top: -30px;
}

.sub-row-box .down {
  font-size: 20pt;
  font-weight: 900;
  margin-bottom: -24px;
}
.sub-row-box .sub-text {
  margin-top: -12px;
  /* margin-left:10px; */
}
.sub-row-line {
  height: 200px;
  width: 4px;
  background: #c8c8c8;
  margin-left: 80px;
  margin-right: 80px;
}
.sm {
  width: 400px;
  height: 260px;
  background-repeat: no-repeat;
  background-size: cover;
}
.sm1 {
  background-image: url("~@/assets/into/sm1.png");
}
.sm2 {
  background-image: url("~@/assets/into/sm2.png");
}
.sm3 {
  background-image: url("~@/assets/into/sm3.png");
}
.sm .sm-title {
  color: white;
  font-size: 26pt;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
}
.sm .sm-sub-title {
  color: white;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
  font-weight: 900;
  font-size:16px;
}
.zz {
  margin-top: 30px;
}
.zz .sm-title {
  font-size: 22pt;
}
.zz .sm-sub-title {
  line-height: 30px;
}
@media screen and (min-width: 1014px) and (max-width: 1910px) {
  .sub-row-box {
    width: 220px;
  }
  .flex-box-row {
    gap: 40px;
  }
  .sub-row-line{
  margin: 0 40px;
}

}

@media screen and (max-width: 1014px) {
  .comp-info-title {
    /* width: 1430%; */
    width: calc(100vw - 100px);
  }

  #point0 .info {
    font-size: 14px;
    line-height: 26px;
    width:  calc(100vw *0.4 );
  }
  #point0 .info-img {
    width:  calc(100vw *0.4 );
  }
  .sub-row-box {
    width: 220px;
  }
  .flex-box-row {
    gap: 20px;
  }
  .sub-row-line {
    margin: 0 20px;
  }

}
</style>
