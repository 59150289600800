<template>
  <div style="flex-wrap: wrap; position: relative">
    <div class="flex-r" style="position: relative;"> 
       <div class="swiper-btn flex-c fg swiper-left-btn" @click="mySwiper.slidePrev()">
        {{ "<" }}
      </div>
      <div class="swiper" style="padding-top: 50px; padding-bottom: 50px">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in services"
          :key="index"
        >
          <div
            class="flex-c ssb"
            style="
              translate: all 0.3s ease;
              box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
              align-items: flex-start;
            "
            :class="{ scl: selectIndex == index }"
          >
            <div class="service-box">
              <el-image
                style="z-index: -1; opacity: 0.8"
                :src="item.bg"
              ></el-image>
              <div class="service-info">
                <div class="service-title">
                  {{ item.title }}
                </div>
                <div class="service-title">
                  {{ item.subTitle }}
                </div>
                <el-image class="service-box-img" :src="item.icon"></el-image>
                <div class="service-en-title">
                  {{ item.en }}
                </div>
              </div>
            </div>
            <div
              class="service-box service-box-info flex-c"
              style="
                margin-top: -6px;
                margin-right: auto;
                align-items: flex-start;
              "
            >
              <div
                class="service-info-list flex-c"
                style="
                  align-items: flex-start;
                  margin-left: 40px;
                  margin-top: 20px;
                  margin-bottom: 40px;
                  margin-right:10px;
                "
              >
                <div v-for="subItem in item.subInfo" class="service-info-text">
                  <div v-html="subItem"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-btn flex-c fg swiper-right-btn" @click="mySwiper.slideNext()">
        {{ ">" }}
      </div>
    </div>
   
    <div class="swiper-btn-list">
    

      <div class="flex-r" style="gap: 10px">
        <div
          v-for="(item, index) in services"
          :key="index"
          @click="mySwiper.slideToLoop(index)"
          class="swiper-point fg"
          :class="{ 'swiper-point-active': index == selectIndex }"
        ></div>
      </div>
    
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
// import Swiper styles
import "swiper/swiper.css";

export default {
  name: "Service",
  setup() {},
  data() {
    return {
      selectIndex: 0,
      mySwiper: null,
      services: [
        {
          id: 1,
          title: "对照药、参比制剂、临床试验",
          subTitle: "器械采购",
          en: "RLD, COMPARATOR & CLINICAL KITS SOURCING",
          bg: require("@/assets/service/b1.png"),
          icon: require("@/assets/service/s1.png"),
          subInfo: [
            "药品与医疗器械全球直采",
            "拥有全球连锁大药房体系",
            "拥有众多医疗器械品牌授权",
            "与众多进口及国产知名品牌厂家深度合作，提供多样化的采购渠道和相配套的安装调试、校准、养护、维修服务",
            "提供试验器械入院后的综合管理服务和使用培训，并为不同类型试验提供试验器械选型与配置的定制化解决方案",
          ],
        },
        {
          id: 2,
          title: "临床试验药品",
          subTitle: "管理服务",
          en: "DRUG DEPOT SERVICES",
          bg: require("@/assets/service/b2.png"),
          icon: require("@/assets/service/s2.png"),
          subInfo: [
            "严格遵守GCP/GSP/GMP合规规则",
            "提供临床标签与辅材的设计与制作、二级包装、药品与器械存储与留样、临床试验发药、药品回收、药品批次销毁的全流程服务",
            "提供药物效期警戒服务、安全库存警戒服务",
            "全程控温，全程追溯，提供温控合规性文档管理",
            "提供IRT等系统数据对接",
          ],
        },
        {
          id: 3,
          title: "生物样本运输与",
          subTitle: "存储服务",
          en: "BIOLOGICAL SAMPLE SHIPPING & STORAGE SERVICES",
          bg: require("@/assets/service/b3.png"),
          icon: require("@/assets/service/s3.png"),
          subInfo: [
            "提供临床试验样本各类温控运输与干冰运输服务",
            "提供细胞制备的手提限时温控运输和液氮运输服务",
            "提供IVD与疫苗的大批量温控运输",
            "提供-80℃生物样本存储和细胞的液氮存储",
            "全程控温，全程追溯，提供温控合规性文档管理",
          ],
        },
        {
          id: 4,
          title: "临床试验供应链管理",
          subTitle: "",
          en: "CLINICAL TRIAL SUPPLY CHAIN MANAGEMENT",
          bg: require("@/assets/service/b4.png"),
          icon: require("@/assets/service/s4.png"),
          subInfo: [
            "根据临床试验方案，提供供应链解决方案",
            "协助申办方优化试验药品采购周期和采购量",
            "通过协同CRO/CDMO的供应链计划，协助申办方大幅加速首家中心首次发药进度",
            "协助申办方优化药品包装盲态保持的解决方案",
            "通过系统算法和精准质控，确保盲法编号的全流程数物一致性",
          ],
        },
        {
          id: 5,
          title: "临床试验冷链管理",
          subTitle: "",
          en: "COLD CHAIN MANAGEMENT",
          bg: require("@/assets/service/b5.png"),
          icon: require("@/assets/service/s5.png"),
          subInfo: [
            "丰富的冷链控制经验，掌握被动式温控核心技术",
            "复杂温层的精确控制，包含15~25℃、2~8℃、-15~-25℃、<br>-20℃以下、-60℃以下、-196℃",
            "温控设备使用最先进的吸塑一体成型技术，具备超长温控时效",
            "使用GPS定位的温度计，实现远程数据实时传输和同步数据云平台管理",
            "提供各类规格的温控设备，所有设备经过严格的静态验证与动态验证",
          ],
        },
        {
          id: 6,
          title: "国际多中心实验的跨境通关",
          subTitle: "与国际运输服务",
          en: "IMPORT AND EXPORT FOR MRCT",
          bg: require("@/assets/service/b6.png"),
          icon: require("@/assets/service/s6.png"),
          subInfo: [
            "精通生物制品、微生物、人体组织、血液和血液制品等各类临床试验相关特殊物品的进出口通关解决方案",
            "依托全球供应链资源，提供可覆盖亚太、欧美、非洲等全球50多个国家的临床试验物流服务",
            "提供全程温控跨境运输、药监局药品通关单办理、海关CIQ申请、临床医疗器械海关三级备案、通关查验、海关申报、海外临床中心配送、海外药品仓库存储等跨国供应链服务，保障国际多中心研究开展",
          ],
        },
        {
          id: 7,
          title: "全球临床试验药品",
          subTitle: "仓库网络",
          en: "GLOBAL DRUG DEPOT NETWORK",
          bg: require("@/assets/service/b7.png"),
          icon: require("@/assets/service/s3.png"),
          subInfo: [
            "在美国田纳西州建有专业临床试验药品仓库，为在美国开展的临床试验提供标签设计制作、二级包装、药品存储与留样、临床试验发药、药品回收、药品销毁、样本运输等全流程服务",
            "在澳大利亚、欧洲、南美具备合作方临床试验药品仓库，提供全流程药品管理服务，为国际多中心研究提供各国临床药品仓库网络的整合，实现多国别 、多中心同步药品管理",
            "所有服务均符合所在国相关药品管理法规要求",
          ],
        },
        {
          id: 8,
          title: "数据支持与质量合规性",
          subTitle: "解决方案",
          en: "DATA SUPPORT & QUALITY ASSURANCE SOLUTION",
          bg: require("@/assets/service/b8.png"),
          icon: require("@/assets/service/s8.png"),
          subInfo: [
            "提供与IRT等系统的数据对接",
            "提供临床试验供应链数据与合规性文档支持",
            "为临床试验的药品管理提供GCP/GSP合规性方案",
            "为临床试验标签设计、试验药包装设计的盲态保持、二级包装批生产放行，提供GCP/GSP合规性评估",
            "为国际多中心研究提供跨境供应链的合规性方案，和临床试验所在国的法规合规性咨询",
          ],
        },
      ],
    };
  },
  mounted() {
    this.initSwiper();
  },
  methods: {

    initSwiper() {
      let that = this;
    this.$nextTick(() => {
      that.mySwiper = new Swiper(".swiper", {
        slidesPerView: window.innerWidth<1010?1:3,
        // spaceBetween:30,
        centeredSlides: true,
        loop: true,
        autoplay: 300,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          slideChangeTransitionStart: function () {
            //   alert(this);//切换结束时，告诉我现在是第几个slide
            console.log(this.realIndex);
            that.selectIndex = this.realIndex;
          },
          init: function () {
            if (that.$route.query.key) {
              console.log(that.$route.query.key);
              that.selectIndex = that.$route.query.key;
              this.slideToLoop(that.$route.query.key);
              document.documentElement.scrollTop = 0;
            }
          },
        },
      });
    });
    },
    showInfo(idx) {
      console.log(idx);
      this.selectIndex = idx;
    },
    goNext() {
      if (this.selectIndex == 7) {
        this.selectIndex = 0;
      } else {
        this.selectIndex++;
      }
    },
  },
};
</script>
<style scoped>
.service-box {
  /* width: 800px; */
  position: relative;
}

.service-info .service-title {
  font-size: 26pt;
  font-weight: 900;
}
.mt {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  /* background: red; */
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
}
.mt .service-box {
  width: 1000px;
  background: white;
  position: relative;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.8);
}
.service-info {
  position: absolute;
  top: 80px;
  left: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
}
.service-info .service-title {
  font-size: 26pt;
  font-weight: 900;
}
.service-info-line {
  width: 600px;
  height: 4px;
  background: #c8c8c8;
}
.service-info-list {
  /* width: 600px; */
  text-align: left;
  font-size: 14pt;
  line-height: 24pt;
  padding-right: 10px;
  min-height: 250px;
}
.service-box-img {
  width: 50px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.service-info-text::before {
  position: absolute;
  content: "●";
  margin-left: -16px;
}
.mt-main {
  position: relative;
  top: 78px;
}
.flote-right {
  float: right;
}
.service-box-info {
  background: #f8f8f8;
  /* height: 422px; */
  width: 100%;
}
.ssb {
  scale: 0.8;
  transition: all 0.3s ease;
}
.scl {
  scale: 1;
}
.swiper-btn-list {
  padding-left: 300px;
  padding-right: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}


.swiper-btn {
  height: 40px;
  width: 40px;
  color: rgba(0, 0, 0, 0.3);
  border-radius: 100px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  font-weight: 900;
  font-size: 28px;
  transition: all 0.3s ease;
  position: absolute;
  z-index:100;
  top:calc(50% - 20px);
}
.swiper-left-btn{
  left:0px;
}
.swiper-right-btn{
  right:0px;
}
.swiper-btn:hover {
  border: 3px solid var(--m-color);
  background: var(--m-color);
  color: white;
}

.swiper-point {
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 100px;
  transition: all 0.5s ease;
}
.swiper-point-active {
  width: 100px;
  background: var(--m-color);
}
@media screen and (min-width: 1010px) and (max-width: 1910px) {
  .swiper-btn-list {
    padding: 0 150px;
  }
  .service-info {
    left: 30px;
    top: 30px;
    /* font-size:24px; */
  }
  .service-info .service-title {
    font-size: 20pt;
  }
  .service-box-img {
    width: 40px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .ssb {
    scale: 0.6;
  }
  .scl {
    scale: 1;
  }
}

@media screen and (max-width: 1010px) {
  .swiper-btn-list {
    padding: 0 50px;
  }
  .scl{
    margin:0 40px;
  }

  .service-info .service-title {
    font-size: 20pt;
  }
  .service-box-img {
    width: 40px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}
</style>
