import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Into from '../views/Into.vue'
import Order from '../views/Order.vue'
import JoinUs from '../views/JoinUs.vue'
import Service from '../views/Service.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/into',
    name: 'Into',
    component:Into
  },
  {
    path: '/service',
    name: 'Service',
    component:Service
  },
  {
    path: '/order',
    name: 'Order',
    component:Order
  },
  {
    path: '/joinus',
    name: 'JoinUs',
    component:JoinUs
  },
  {
    path: '/about',
    name: 'About',
    component:About
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history:createWebHashHistory(),
  routes
})

export default router
