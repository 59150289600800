<template>
  <div class="home flex-c">
    <div class="videoContainer" >
      <video
        class="fullscreenVideo"
        id="bgVid"
        playsinline=""
        autoplay=""
        muted=""
        loop=""
      >
        <source
          :src="`${publicPath}/video/main-video.mp4`"
          type="video/mp4"
        />
      </video>
      <div class="video-info">
        <div>
          <div class="info-text">为医药健康产品提供全生命链保障</div>
        <div class="info-text">为推动行业标准进步不断贡献力量</div>
        </div>
        <div class="info-button fg" style="z-index:100000" @click="goPage" >
          走进佰诚
    </div>
      </div>
    </div>
    <div id="main-sub1" class="flex-r" style="display: none;">
      <div class="sub-row-box flex-r">
        <el-image  class="icon" :src="require('@/assets/main/icon1.png')" ></el-image>
        <div class="flex-c" style="align-items: flex-start;">
          <div class="flex-r">
            <div class="num">30</div><div class="up">+</div>
          </div>
          <div class="sub-text">全国自营网点</div>
        </div>
      </div>
      <div class="sub-row-box flex-r">
        <el-image class="icon" :src="require('@/assets/main/icon2.png')" ></el-image>
        <div class="flex-c"   style="align-items: flex-start;">
          <div class="flex-r" >
            <div class="num">150</div><div class="up">+</div>
          </div>
          <div class="sub-text">覆盖城市</div>
        </div>
      </div>
      <div class="sub-row-box flex-r">
        <el-image  class="icon" :src="require('@/assets/main/icon3.png')" ></el-image>
        <div class="flex-c"   style="align-items: flex-start;">
          <div class="flex-r">
            <div class="num">98</div><div class="down">%</div>
          </div>
          <div class="sub-text">覆盖全国行政县</div>
        </div>
      </div>
      <div class="sub-row-box flex-r">
        <el-image  class="icon" :src="require('@/assets/main/icon4.png')" ></el-image>
        <div class="flex-c"   style="align-items: flex-start;">
          <div class="flex-r">
            <div class="num">2000</div><div class="down">m²</div>
          </div>
          <div class="sub-text">数字化多温区仓储管理</div>
        </div>
      </div>

      <div class="sub-row-box flex-r">
        <el-image class="icon" :src="require('@/assets/main/icon5.png')" ></el-image>
        <div class="flex-c"   style="align-items: flex-start;">
          <div class="flex-r">
            <div class="num">50</div><div class="up">+</div>
          </div>
          <div class="sub-text">药企战略合作客户</div>
        </div>
      </div>
      <div class="sub-row-box flex-r">
        <el-image class="icon" :src="require('@/assets/main/icon6.png')" ></el-image>
        <div class="flex-c"   style="align-items: flex-start;">
          <div class="flex-r">
            <div class="num">800</div><div class="up">+</div>
          </div>
          <div class="sub-text">对照药主要供应商</div>
        </div>
      </div>
    </div>
    <!--服务领域-->
    <div id="main-sub2" class="main-sub2 flex-c">
      <div class="sub-title flex-c">
        <div class="main-title">服务领域</div>
        <div class="line flex-c">
          <div class="point"></div>
        </div>
        <div class="sub-title-text">SERVICE AREA</div>
      </div>
      
      <div class="flex-r" style="flex-wrap: wrap;gap:37px">
        <div class="flex-c service-box " v-for="(item,index) in services" @click="goService(index)">
          <el-image style="width:400px;height:260px" fit="cover" :src="item.img"></el-image>
          <div class="title">{{ item.title }}</div>
          <div class="service-btn fg">了解详情</div>
        </div>
      </div>
    </div>
    <!-- 全球多仓化药品管理-->
    <div class="main-sub2">
      <div class="sub-title flex-c">
        <div class="main-title">全球多仓化药品管理</div>
        <div class="line flex-c">
          <div class="point"></div>
        </div>
        <div class="sub-title-text">DRUG DEPOT SERVICE</div>
      </div>
      <div style="margin:40px">
        <el-image :src="require('@/assets/main/map.png')"></el-image>
      </div>
    </div>
    <div id="main-sub4" class="main-sub2 flex-c" style="justify-content: flex-start;">
      <div class="sub-title flex-c">
        <div class="main-title">佰诚优势</div>
        <div class="line flex-c">
          <div class="point"></div>
        </div>
        <div class="sub-title-text">BIOQUICK ADVANTAGE</div>
      </div>
      <div class="flex-r" style="flex-wrap: wrap;gap:37px;max-width: 1430px;">
        <div class="flex-r adv-box" v-for="item in advanice">
          <div class="img-box"><el-image style="width:50;height:50" fit="cover" :src="item.img"></el-image></div>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="main-sub2 flex-c">
      <div class="sub-title flex-c">
        <div class="main-title">合作伙伴</div>
        <div class="line flex-c">
          <div class="point"></div>
        </div>
        <div class="sub-title-text">COOPERATIVE PARTNER</div>
      </div>
      <div class="flex-r" style="flex-wrap: wrap;width:80%">
        <el-image v-for="item in pnList" :key="item" fit="contain" style="margin:30px 20px;max-width:120px" :src="require('@/assets/main/'+item+'.png')"></el-image>
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data(){
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      services:[
        {
          img:require('@/assets/main/service1.png'),
          title:'对照药、参比制剂、临床试验器材采购'
        },
        {
          img:require('@/assets/main/service2.png'),
          title:'临床试验药品管理服务'
        },
        {
          img:require('@/assets/main/service3.png'),
          title:'生物样本运输与存储服务'
        },
        {
          img:require('@/assets/main/service4.png'),
          title:'临床试验供应链管理'
        },
        {
          img:require('@/assets/main/service5.png'),
          title:'临床试验冷链管理'
        },
        {
          img:require('@/assets/main/service6.png'),
          title:'国际多中心实验的跨境通关与国际运输服务'
        },
        {
          img:require('@/assets/main/service7.png'),
          title:'全球临床试验药品仓库网络'
        },
        {
          img:require('@/assets/main/service8.png'),
          title:'数据支持与质量合规性解决方案'
        }
      ],
      advanice:[
        {
          img:require('@/assets/main/XC11-01.png'),
          title:"完善的质量保障体系",
        },
        {
          img:require('@/assets/main/XC11-02.png'),
          title:"健全的信息系统保障",
        },{
          img:require('@/assets/main/XC11-03.png'),
          title:"过硬的温控技术保障",
        },{
          img:require('@/assets/main/XC11-04.png'),
          title:"全球化供应链整合能力",
        },{
          img:require('@/assets/main/XC11-05.png'),
          title:"全球化资源交互能力",
        },{
          img:require('@/assets/main/XC11-06.png'),
          title:"GCP&GSP&GMP认证",
        }
      ],
      pnList:[
      'pn01',
'pn02',
'pn06',
'pn16',
'pn03',
'pn20',
'pn18',
'pn21',
'pn22',
'pn23',
'pn15',
'pn13',
'pn24',
'pn17',
'pn14',
'pn25',
'pn26',


'pn19'
      ]
    };
  },
  methods:{
    goPage(){
      this.$router.push('/into')
    },
    goService(idx){
      this.$router.push({
        path:'/service',
        query:{
          key:idx
        }
      })
    }
  }
};
</script>
<style scoped>
.videoContainer{
  width: 100%;
  /* height:910px; */
  overflow: hidden;
  /* z-index: -100; */
  position: relative;
}

.videoContainer:before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  /* background: rgba(25,29,34,.65); */
  background-size: cover;
}

.fullscreenVideo{
  width: 100%;
  height: 100%;
  object-fit: fill;
  opacity: 0.8;
  z-index:0;
}

.video-info{
  z-index:100;
  position: absolute;
  left:180px;
  top:0;
  padding-top:180px;
  height:100%;
  /* background: red; */
  color:white;
  font-size:26pt;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.info-text{
  margin-top:10px;
  text-shadow: 2px 2px 2px rgba(0,0,0,0.2);
}
.info-button{
  color:#1682D8;
  font-size:20px;
  margin-top:80px;
  background-color: white;
  width:160px;
  height:40px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#main-sub1{
  width:100%;
  background:#F8F9FF;
  height:200px;
}

.sub-row-box{
  width:240px;
  height:80px;
  /* background: rgba(0,0,0,0.2); */
  margin-left:5px;
  margin-right:5px;
}
.sub-row-box .icon{
  /* width:24px; */
  margin-right:20px;
  margin-top:10px;
}
.sub-row-box .num{
  font-size:42pt;
  font-weight: 900;
  font-family: 'bah';
}

.sub-row-box .up{
  font-size:20pt;
  font-weight: 900;
  margin-top:-30px;
}

.sub-row-box .down{
  font-size:20pt;
  font-weight: 900;
  margin-bottom:-24px;
}
.sub-row-box .sub-text{
  margin-top:-12px;
  /* margin-left:10px; */
}
.service-box{
  transition: all ease 0.3s;
  padding-bottom:20px;
}
.service-box:hover{
  transition: all ease 0.3s;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.4);
}
.service-box .title{
  font-size:16px;
  margin-top:10px;
}
.service-box .service-btn{
  font-size:12px;
  margin-top:10px;
  width:100px;
  height:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border:1px solid black;
  transition: all ease 0.3s;
}
.service-btn:hover{
  background: #1682D8;
  border:1px solid #1682D8;
  color:white
}

#main-sub4{
  background-image:url('~@/assets/main/bg-sub4.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width:100%;
  /* height:600px; */
  padding-bottom:60px;

}
#main-sub4 .img-box{
  width:70px;
  height:70px;
  background: #D6D6D6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-left:20px;
  margin-right:30px;
  transition: all ease 0.3s;
  filter: grayscale(100%);
}


#main-sub4 .adv-box{
  background:white;
  border-radius: 5px;
  width:400px;
  height:130px;
  justify-content: flex-start;
  font-size:22px;
  transition: all 0.5s ease;
}

#main-sub4 .adv-box:hover{
  /* font-weight: 900; */
  color: #1682D8;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.2);
}

#main-sub4 .adv-box:hover .img-box{
  /* box-shadow: 4px 4px 1px #D6D6D6; */
  /* transform: rotateY(45deg); */
  /* background: #1682D8; */
  filter: grayscale(0%)
}

@media screen and (min-width:1430px) and (max-width:1910px) {
  .video-info{
    left:10%;
    padding-top:10%;
  }
}

@media screen and (min-width:1014px) and (max-width:1430px) {
  .video-info{
    left:10%;
    padding-top:10%;
  }
}
@media screen  and (max-width:1014px) {
  .video-info{
    left:10%;
    padding-top:10%;
  }
  .info-button{
    margin-top:40px;
  }
}

</style>
