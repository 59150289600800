<template>
    <div></div>
</template>
<script>

export default({
    name: "JoinUs",
    setup() {
        
    },
})
</script>
<style scoped>

</style>
